import React from "react";
import "../social_icons/style.css";
import { Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faGithub,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

function SocialIcons() {
  return (
    /*&nbsp; &nbsp; &nbsp; &nbsp; */
    <Col xl={12} className="social-icons">
      <a
        href="https://www.facebook.com/profile.php?id=100093050435995"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="fb" icon={faFacebook} />
      </a>
      <a
        href="https://github.com/BryanLomerio"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="git" icon={faGithub} />
      </a>
      <a
        href="https://instagram.com/aninotoff"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="insta" icon={faInstagram} />
      </a>
      <a
        href="https://youtube.com/@bryanlomerio2762?si=VE69ECVAuK_0DaCs"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="yt" icon={faYoutube} />
      </a>
      <a
        href="https://www.linkedin.com/in/bryan-lomerio-26562123a/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="linkedin" icon={faLinkedin} />
      </a>
      <p>
        <a href="https://www.buymeacoffee.com/BryanLomerio">
          <img
            align="center"
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            height="30"
            width="100"
            alt="AninoDev"
          />
        </a>
      </p>
    </Col>
  );
}

export default SocialIcons;
